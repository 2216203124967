import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { listAllProjects, createProject, deleteProject } from "../utils/api";
import { jwtDecode } from "jwt-decode"; // Correção: jwtDecode é uma função padrão, não um objeto
import { FaTrash, FaSpinner } from "react-icons/fa"; // Importar ícones

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectAbbreviation, setProjectAbbreviation] = useState("");
  const [projectType, setProjectType] = useState("googleads/admanager");
  const [userId, setUserId] = useState(null);
  const [canCreateProject, setCanCreateProject] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});

  // Estados para as mensagens de validação
  const [nameError, setNameError] = useState("");
  const [abbreviationError, setAbbreviationError] = useState("");

  useEffect(() => {
    const checkPermissions = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.scopes && decodedToken.scopes.includes("projects")) {
          setHasPermission(true);
          setUserId(decodedToken.id);
          setCanCreateProject(decodedToken.scopes.includes("gads-integration"));
          fetchProjects();
        }
      }
    };

    checkPermissions();
  }, []);

  const fetchProjects = async () => {
    setLoadingProjects(true);
    try {
      const projectsData = await listAllProjects();
      setProjects(projectsData);
    } catch (error) {
      toast.error("Erro ao listar os projetos.");
      console.error("Erro ao listar projetos:", error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const handleCreateProject = async () => {
    if (nameError || abbreviationError) {
      toast.error("Por favor, corrija os erros antes de prosseguir.");
      return;
    }

    setLoadingCreate(true);

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      const newProjectData = {
        projectName,
        projectAbbreviation,
        projectType,
        accountId: accountId,
      };

      const response = await createProject(newProjectData);

      if (response && response.id) {
        setIsModalOpen(false); // Fecha o modal após a criação do projeto
        setProjectName("");
        setProjectAbbreviation("");
        setNameError("");
        setAbbreviationError("");
        fetchProjects(); // Recarrega a lista de projetos após a criação
        toast.success("Projeto criado com sucesso!");
      } else {
        // Se a resposta da API não for sucesso, lança um erro
        throw new Error(response.error || "Erro ao criar o projeto");
      }
    } catch (error) {
      toast.error(`Erro ao criar o projeto: ${error.message}`);
      console.error("Erro ao criar projeto:", error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleProjectClick = (projectId) => {
    window.location.href = `/project/${projectId}`; // Redireciona para o dashboard dinâmico do projeto
  };

  const renderProjectRole = (project) => {
    if (project.accountId === userId) {
      return <span className="text-green-600 font-semibold">Proprietário</span>;
    } else if (project.trafficManagersIds?.includes(userId)) {
      return <span className="text-blue-600 font-semibold">Gerente</span>;
    }
    return <span className="text-gray-600">Visualizador</span>;
  };

  // Função para validar o nome do projeto
  const validateProjectName = (name) => {
    // Transforma a primeira letra em maiúscula
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
    setProjectName(formattedName);

    // Validação: máximo de 20 caracteres ou 4 palavras
    const wordCount = formattedName.trim().split(/\s+/).length;
    if (formattedName.length > 20 || wordCount > 4) {
      setNameError("O nome deve ter no máximo 20 caracteres ou 4 palavras.");
    } else {
      setNameError("");
    }
  };

  // Função para validar a sigla do projeto
  const validateProjectAbbreviation = (abbreviation) => {
    // Remove espaços e caracteres especiais, transforma em maiúsculas
    const formattedAbbreviation = abbreviation
      .replace(/[^A-Za-z0-9]/g, "")
      .toUpperCase();
    setProjectAbbreviation(formattedAbbreviation);

    // Validação: entre 3 e 7 caracteres
    if (formattedAbbreviation.length < 3 || formattedAbbreviation.length > 7) {
      setAbbreviationError("A sigla deve ter entre 3 e 7 caracteres.");
    } else {
      setAbbreviationError("");
    }
  };

  const handleDeleteProject = async (projectId) => {
    setLoadingDelete((prev) => ({ ...prev, [projectId]: true }));
    try {
      const response = await deleteProject(projectId);

      if (response.success) {
        // Verifique se o success foi retornado
        toast.success("Projeto deletado com sucesso!");
        fetchProjects(); // Recarrega a lista de projetos após a deleção
      } else {
        // Caso a API não retorne sucesso, exibe erro
        throw new Error(response.error || "Erro ao deletar projeto");
      }
    } catch (error) {
      toast.error(`Erro ao deletar o projeto`);
      console.error("Erro ao deletar projeto:", JSON.stringify(error));
    } finally {
      setLoadingDelete((prev) => ({ ...prev, [projectId]: false }));
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
        {hasPermission ? (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-gray-700">
              Meus Projetos
            </h1>
            {loadingProjects ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-blue-500 text-3xl" />
              </div>
            ) : projects.length > 0 ? (
              <ul className="space-y-4">
                {projects.map((project) => (
                  <li
                    key={project.id}
                    className="bg-gray-200 p-3 rounded shadow hover:bg-gray-300 cursor-pointer transition-colors duration-200 flex justify-between items-center"
                  >
                    <div onClick={() => handleProjectClick(project.id)}>
                      <h2 className="text-lg font-semibold text-gray-800">
                        {project.projectName || project.projectType}
                      </h2>
                      <p className="text-sm text-gray-600">
                        Sigla: {project.projectAbbreviation}
                      </p>
                      <p className="text-sm text-gray-600">ID: {project.id}</p>
                      <p className="text-sm text-gray-600">
                        Status: {renderProjectRole(project)}
                      </p>
                    </div>
                    {project.accountId === userId && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Evita o clique no projeto
                          handleDeleteProject(project.id);
                        }}
                        disabled={loadingDelete[project.id]}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        {loadingDelete[project.id] ? (
                          <FaSpinner className="animate-spin" />
                        ) : (
                          <FaTrash />
                        )}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center">
                <p className="text-gray-600 mb-4">
                  Você ainda não possui projetos. Crie seu primeiro projeto!
                </p>
                {canCreateProject && (
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200"
                  >
                    Criar Primeiro Projeto
                  </button>
                )}
              </div>
            )}
            {projects.length > 0 && canCreateProject && (
              <div className="text-center mt-6">
                <button
                  onClick={() => setIsModalOpen(true)}
                  disabled={loadingCreate}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
                >
                  {loadingCreate ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : null}
                  Criar Mais Um Projeto
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-red-600">Acesso Negado</h2>
            <p className="text-gray-600">
              Você não tem permissão para gerenciar projetos. Por favor, entre
              em contato com o administrador.
            </p>
          </div>
        )}
      </div>

      {/* Modal para Criação de Projeto */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-lg font-semibold mb-3 text-gray-700">
              Novo Projeto
            </h2>
            <input
              type="text"
              placeholder="Nome do Projeto"
              value={projectName}
              onChange={(e) => validateProjectName(e.target.value)}
              className="w-full p-2 border border-blue-200 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-blue-300"
            />
            {nameError && (
              <p className="text-xs text-red-500 mb-2">{nameError}</p>
            )}

            <input
              type="text"
              placeholder="Sigla do Projeto"
              value={projectAbbreviation}
              onChange={(e) => validateProjectAbbreviation(e.target.value)}
              className="w-full p-2 border border-blue-200 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-blue-300"
            />
            {abbreviationError && (
              <p className="text-xs text-red-500 mb-2">{abbreviationError}</p>
            )}

            <select
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
              className="w-full p-2 border border-blue-200 rounded mb-3 bg-white text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              disabled
            >
              <option value="googleads/admanager">
                Arbitragem - AdManager/Google Ads
              </option>
              {/* Outros tipos desabilitados */}
            </select>

            <p className="text-xs text-gray-500 mt-2">
              Atenção: Todos os projetos que utilizam AdManager devem
              obrigatoriamente incluir parâmetros UTM. Esses parâmetros devem
              estar presentes no link final dos anúncios na plataforma
              selecionada.
            </p>

            <div className="flex justify-end space-x-3 mt-4">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  // Limpa os campos e mensagens de erro ao fechar o modal
                  setProjectName("");
                  setProjectAbbreviation("");
                  setNameError("");
                  setAbbreviationError("");
                }}
                className="bg-gray-200 text-gray-600 py-2 px-4 rounded hover:bg-gray-300 transition duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateProject}
                disabled={loadingCreate}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-150 flex items-center"
              >
                {loadingCreate ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : null}
                Criar Projeto
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
