// CampaignPerformanceTable.jsx

import React, { useState, useEffect, useMemo } from "react";
import {
  FaExternalLinkAlt,
  FaInfoCircle,
  FaPencilAlt,
  FaCheck,
  FaLink,
} from "react-icons/fa";
import { modifyAdGroupBid, getRealTimeEvents } from "../utils/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import CpcEditor from "./CpcEditor";
import LastCpcAdjust from "./LastAdjustCpc";
import DisplayEventCount from "./analytics/DisplayEventCount";
import PreviewReport from "./performanceTable/previewsReports";
import CpcCampaignDataCell from "./performanceTable/cpcMaxAndMin";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import TextWithTooltip from "./TextWithTooltip";

dayjs.extend(utc);
dayjs.extend(timezone);

const CampaignPerformanceTable = ({
  reports,
  singleCampaign = false,
  config,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableCPC, setEditableCPC] = useState(null);
  const [editedCPCValue, setEditedCPCValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gaEventList, setGaEventList] = useState([]);

  const projectId = reports.length > 0 ? reports[0].projectId : undefined;

  useEffect(() => {
    let intervalId;

    if (!singleCampaign && projectId) {
      const fetchGaEvents = async () => {
        try {
          const result = await getRealTimeEvents(projectId);
          if (result.success) {
            setGaEventList(result.ga4events || []);
          } else {
            console.error("Failed to fetch GA events:", result.error);
          }
        } catch (error) {
          console.error("Error fetching GA events:", error);
        }
      };

      fetchGaEvents();
      intervalId = setInterval(fetchGaEvents, 60000);
    }

    return () => clearInterval(intervalId);
  }, [singleCampaign, projectId]);

  const openModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleEditCPC = (campaignId, adGroupId, currentValue) => {
    setEditableCPC(campaignId);
    setEditedCPCValue(currentValue);
  };

  const handleSaveCPC = async (
    campaignId,
    adGroupId,
    projectId,
    biddingStrategyType,
    currentValue
  ) => {
    if (parseFloat(editedCPCValue) === parseFloat(currentValue)) {
      toast.error("O valor inserido é o mesmo que o atual.");
      return;
    }

    setIsSubmitting(true);

    const adjustType =
      biddingStrategyType === 10 ? "target_cpa_micros" : "cpc_bid_micros";

    const response = await modifyAdGroupBid({
      projectId,
      campaignId,
      adGroupId,
      adjustValue: parseFloat(editedCPCValue),
      adjustType,
    });

    if (response.success) {
      toast.success(response.message || "CPC atualizado com sucesso!");

      setEditableCPC(null);
    } else {
      toast.error(response.message || "Falha ao atualizar o CPC.");
    }

    setIsSubmitting(false);
  };

  // Função para calcular cpcMin e cpcMax com base nos dados e na configuração
  const calculateCpcValue = (campaign, type) => {
    // Sua lógica para calcular o cpcMin ou cpcMax
    // Exemplo simples:
    const baseCpc = parseFloat(campaign.currentBid) || 0;
    const adjustment = config?.cpcAdjustment || 0;

    if (type === "min") {
      return baseCpc - adjustment;
    } else if (type === "max") {
      return baseCpc + adjustment;
    }
    return baseCpc;
  };

  // Definição das colunas para o Material React Table
  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "info",
        id: "info",
        header: "",
        enableColumnOrdering: false,
        enableHiding: false,
        enableSorting: false,
        enableFiltering: false,
        size: 40,
        Cell: ({ row }) => (
          <FaInfoCircle
            className="text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={() => openModal(row.original)}
          />
        ),
      },
      // Inserção da coluna 'Data' após 'info' quando 'singleCampaign' é true
      ...(singleCampaign
        ? [
            {
              id: "createdAt",
              header: "Data",
              accessorKey: "createdAt",
              minSize: 100,
              enableSorting: true,
              filterFn: "between",
              filterVariant: "date",
              Cell: ({ cell }) => dayjs(cell.getValue()).format("DD/MM HH:mm"),
            },
          ]
        : []),
      {
        id: "campaignName",
        header: "Nome da Campanha",
        accessorFn: (row) => row.campaignName || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        id: "adGroupName",
        header: "Nome do Grupo",
        accessorFn: (row) => row.adGroupName || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "utm",
        id: "utm",
        header: "UTM",
        minSize: 100,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell, row }) => (
          <div className="flex items-center gap-2">
            <a
              href={`https://ads.google.com/aw/adgroups?campaignId=${row.original.campaignId}&__c=${row.original.customerId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-blue-500 hover:underline"
            >
              {cell.getValue()} <FaExternalLinkAlt />
            </a>
            {row.original.campaignDetails.adGroups[0]?.ads[0]
              ?.final_urls[0] && (
              <a
                href={`${row.original.campaignDetails.adGroups[0]?.ads[0]?.final_urls[0]}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-blue-500 hover:underline"
              >
                <FaLink />
              </a>
            )}
          </div>
        ),
      },
      // Ajuste da coluna 'G-Analytics' para usar o componente 'DisplayEventCount'
      ...(!singleCampaign
        ? [
            {
              id: "gaEvents",
              header: "G-Analytics",
              accessorKey: "utm",
              minSize: 100,
              enableSorting: true,
              filterFn: "between",
              filterVariant: "range",
              Cell: ({ cell }) => (
                <DisplayEventCount
                  eventName={String(cell.getValue())}
                  eventList={gaEventList}
                />
              ),
            },
          ]
        : []),
      {
        id: "cpcAdjustTime",
        header: "Último ajuste de CPC",
        accessorFn: (row) => row.cpcInfo?.adjustTime || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        // Ajuste para que o componente 'LastCpcAdjust' não quebre o layout
        Cell: ({ row }) => <LastCpcAdjust cpcInfo={row.original.cpcInfo} />,
      },
      {
        id: "biddingStrategy",
        header: "Estratégia de Lances",
        accessorFn: (row) => {
          const bid = row.campaignDetails.bidding_strategy_type;
          let biddingStrategy;

          if (bid === "cpc_bid_micros") {
            biddingStrategy = "CPC Manual";
          } else if (bid === "enhanced_cpc") {
            biddingStrategy = "CPC Otimizado";
          } else if (bid === "target_cpa_micros") {
            biddingStrategy = "CPA Desejado";
          } else if (bid === "target_roas_micros") {
            biddingStrategy = "ROAS Desejado";
          } else if (bid === "cpm_bid_micros") {
            biddingStrategy = "CPM Manual";
          } else if (bid === "cpc_bid_ceiling_micros") {
            biddingStrategy = "Maximizar cliques";
          } else if (bid === "effective_target_cpa_micros") {
            biddingStrategy = "Maximizar Conversões";
          } else if (bid === "commission_rate_micros") {
            biddingStrategy = "Comissão";
          } else if (bid === "maximize_conversions") {
            biddingStrategy = "Maximizar Conversões";
          } else if (bid === "target_spend") {
            biddingStrategy = "Gasto Desejado";
          } else {
            biddingStrategy = "CPC Manual";
          }
          return biddingStrategy;
        },
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
      },
      {
        id: "cpcMin",
        header: "CPC Min (R$)",
        accessorFn: (row) => {
          const cpcMinValue = calculateCpcValue(row, "min");
          return parseFloat(cpcMinValue) || 0;
        },
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => (
          <CpcCampaignDataCell
            campaign={row.original}
            config={config}
            type="min"
          />
        ),
      },
      {
        id: "cpc",
        header: "CPC (R$)",
        accessorFn: (row) => parseFloat(row.currentBid) || 0,
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => {
          const campaignId = row.original.campaignId;
          const adGroupId = row.original.adGroupId;
          const bid = row.original.campaignDetails.bidding_strategy_type;
          const cpcInReais = row.original.currentBid
            ? row.original.currentBid
            : "∞";

          return (
            <div className="flex items-center gap-2">
              {editableCPC === campaignId ? (
                <input
                  type="text"
                  value={editedCPCValue}
                  onChange={(e) => setEditedCPCValue(e.target.value)}
                  className="border border-gray-300 p-0.5 text-xs rounded flex-grow w-full max-w-xs"
                  disabled={isSubmitting}
                />
              ) : (
                `R$ ${cpcInReais}`
              )}
              {!singleCampaign && editableCPC !== campaignId && (
                <FaPencilAlt
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() =>
                    handleEditCPC(campaignId, adGroupId, cpcInReais)
                  }
                />
              )}
              {editableCPC === campaignId && (
                <FaCheck
                  className={`${
                    isSubmitting ? "text-gray-400" : "text-green-500"
                  } cursor-pointer`}
                  onClick={() =>
                    !isSubmitting &&
                    handleSaveCPC(
                      campaignId,
                      adGroupId,
                      row.original.projectId,
                      bid,
                      cpcInReais
                    )
                  }
                />
              )}
            </div>
          );
        },
      },

      {
        id: "cpcMax",
        header: "CPC Max (R$)",
        accessorFn: (row) => {
          const cpcMaxValue = calculateCpcValue(row, "max");
          return parseFloat(cpcMaxValue) || 0;
        },
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => (
          <CpcCampaignDataCell
            campaign={row.original}
            config={config}
            type="max"
          />
        ),
      },
      {
        accessorKey: "roiPercentage",
        header: "ROI (%)",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const roiStyle =
            value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-black";
          return (
            <span className={`font-semibold ${roiStyle}`}>
              {value?.toFixed(2)}%
            </span>
          );
        },
      },
      {
        accessorKey: "googleAdsCost",
        header: "Total Gasto",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => `R$ ${cell.getValue()?.toFixed(2)}`,
      },
      {
        accessorKey: "roiRevenue",
        header: "Lucro",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const lucroStyle =
            value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-black";
          return <span className={lucroStyle}>R$ {value?.toFixed(2)}</span>;
        },
      },
      {
        accessorKey: "previewsRegister1",
        header: "Registro anterior ~20",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={2} />
        ),
      },
      {
        accessorKey: "previewsRegister2",
        header: "Registro anterior ~40",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={1} />
        ),
      },
      {
        accessorKey: "previewsRegister3",
        header: "Registro anterior ~60",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={0} />
        ),
      },
      {
        accessorKey: "adManagerRevenueBRL",
        header: "Total Ganho (BRL)",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() !== null && cell.getValue() !== undefined
            ? `R$ ${cell.getValue().toFixed(2)}`
            : "N/A",
      },
      {
        accessorKey: "adManagerRevenueUSD",
        header: "Total Ganho (USD)",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() !== null && cell.getValue() !== undefined
            ? `$${cell.getValue().toFixed(2)}`
            : "N/A",
      },
      {
        accessorKey: "googleAdsClicks",
        header: "Google Ads Clicks",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        id: "conversions",
        header: "Google Ads Conversions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        accessorFn: (row) => row.campaignDetails?.metrics?.conversions || 0,
        Cell: ({ cell }) => cell.getValue().toFixed(1),
      },
      {
        accessorKey: "googleAdsImpressions",
        header: "Google Ads Impressions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerClicks",
        header: "Ad Manager Clicks",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerImpressions",
        header: "Ad Manager Impressions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerPmr",
        header: "PMR",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() ? `${(cell.getValue() * 100).toFixed(2)}%` : "N/A",
      },
    ];

    return baseColumns;
  }, [
    editableCPC,
    editedCPCValue,
    isSubmitting,
    gaEventList,
    config,
    singleCampaign,
  ]);

  // Atualizar o columnOrder quando columns ou singleCampaign mudar
  const [columnOrder, setColumnOrder] = useState(() => {
    const savedOrder = localStorage.getItem("columnOrder");
    if (savedOrder && savedOrder !== "undefined") {
      try {
        return JSON.parse(savedOrder);
      } catch (e) {
        console.error("Falha ao analisar columnOrder do localStorage:", e);
        localStorage.removeItem("columnOrder");
      }
    }
    return columns.map((column) => column.id || column.accessorKey);
  });

  useEffect(() => {
    // Atualizar o columnOrder quando singleCampaign ou columns mudar
    const newColumnOrder = columns.map(
      (column) => column.id || column.accessorKey
    );
    setColumnOrder(newColumnOrder);
  }, [singleCampaign, columns]);

  // useEffect para salvar columnOrder no localStorage quando mudar
  useEffect(() => {
    localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
  }, [columnOrder]);

  // Definir ordenação inicial
  const initialSorting = [
    {
      id: "googleAdsCost",
      desc: true,
    },
  ];

  // useEffect para salvar columnVisibility no localStorage quando mudar
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem("columnVisibility");
    if (savedVisibility && savedVisibility !== "undefined") {
      try {
        return JSON.parse(savedVisibility);
      } catch (e) {
        console.error("Falha ao analisar columnVisibility do localStorage:", e);
        localStorage.removeItem("columnVisibility");
      }
    }
    return {};
  });

  useEffect(() => {
    localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  // Estado para armazenar os tamanhos das colunas
  const [columnSizing, setColumnSizing] = useState(() => {
    const savedSizing = localStorage.getItem("columnSizing");
    if (savedSizing && savedSizing !== "undefined") {
      try {
        return JSON.parse(savedSizing);
      } catch (e) {
        console.error("Falha ao analisar columnSizing do localStorage:", e);
        localStorage.removeItem("columnSizing");
      }
    }
    return {};
  });

  // useEffect para salvar columnSizing no localStorage quando mudar
  useEffect(() => {
    localStorage.setItem("columnSizing", JSON.stringify(columnSizing));
  }, [columnSizing]);

  return (
    <div className="max-w-full">
      {/* Removido o checkbox de truncamento */}
      <div className="flex justify-between items-center mb-2">
        <div></div> {/* Espaço vazio para alinhar o horário à direita */}
        <div className="flex justify-end">
          <span className="m-2 text-xs">
            <strong>Horário do relatório: </strong>
            {reports[0].createdAt
              ? dayjs(reports[0].createdAt).format("DD/MM HH:mm")
              : "N/A"}
          </span>
        </div>
      </div>

      <MaterialReactTable
        columns={columns}
        data={reports}
        enableColumnOrdering
        enableRowSelection
        enableColumnDragging
        enableColumnResizing
        enableGlobalFilter
        enableColumnFilterModes
        enableColumnFilters
        enableColumnHiding
        enableSorting
        localization={MRT_Localization_PT_BR}
        muiTableBodyCellProps={{
          style: { fontSize: "0.75rem" },
        }}
        muiTableHeadCellProps={{
          style: {
            fontSize: "0.75rem",
            whiteSpace: "nowrap",
          },
        }}
        state={{
          columnVisibility,
          columnOrder,
          columnSizing,
          isLoading: false,
        }}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onColumnSizingChange={setColumnSizing}
        initialState={{
          showGlobalFilter: true,
          density: "compact",
          sorting: initialSorting,
        }}
        columnResizeMode="onChange"
        muiToolbarAlertBannerProps={undefined}
      />

      {isModalOpen && selectedCampaign && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: 999999 }}
        >
          <div className="bg-white rounded-lg w-full max-w-md p-4 shadow-lg relative mx-2 max-h-[80vh] overflow-y-auto">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <h2 className="text-lg font-semibold text-gray-800 mb-4 mt-6">
              Detalhes da Campanha: {selectedCampaign.campaignName}
            </h2>
            <div className="text-gray-700 space-y-2">
              <div className="flex justify-between">
                <span className="font-medium">ID da Campanha:</span>
                <span>{selectedCampaign.campaignId}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Nome da Campanha:</span>
                <span>{selectedCampaign.campaignName}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">ID do Grupo:</span>
                <span>{selectedCampaign.adGroupId}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Nome do Grupo:</span>
                <span>{selectedCampaign.adGroupName || "N/A"}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">UTM:</span>
                <span>{selectedCampaign.utm || "N/A"}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">ROI (%):</span>
                <span>
                  {selectedCampaign.roiPercentage
                    ? `${selectedCampaign.roiPercentage.toFixed(2)}%`
                    : "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Lucro:</span>
                <span>
                  R${" "}
                  {selectedCampaign.roiRevenue
                    ? selectedCampaign.roiRevenue.toFixed(2)
                    : "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Gasto:</span>
                <span>
                  R${" "}
                  {selectedCampaign.googleAdsCost
                    ? selectedCampaign.googleAdsCost.toFixed(2)
                    : "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Ganho (BRL):</span>
                <span>
                  R${" "}
                  {selectedCampaign.adManagerRevenueBRL
                    ? selectedCampaign.adManagerRevenueBRL.toFixed(2)
                    : "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Ganho (USD):</span>
                <span>
                  ${" "}
                  {selectedCampaign.adManagerRevenueUSD
                    ? selectedCampaign.adManagerRevenueUSD.toFixed(2)
                    : "N/A"}
                </span>
              </div>
              {/* Adicione quaisquer outras informações necessárias aqui */}
            </div>

            <CpcEditor
              campaignId={selectedCampaign.campaignId}
              adGroupId={selectedCampaign.adGroupId}
              onClose={closeModal}
            />

            <div className="mt-4 flex justify-end space-x-4">
              <Link
                to={`/project/${selectedCampaign.projectId}/performance/${selectedCampaign.campaignId}/${selectedCampaign.adGroupId}`}
                className="text-blue-600 hover:underline"
                onClick={closeModal}
              >
                Ver Histórico Completo
              </Link>
              <button
                className="text-gray-600 hover:underline"
                onClick={closeModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignPerformanceTable;
