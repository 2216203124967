import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCampaignReportByDate } from "../utils/api";
import dayjs from "dayjs";
import CampaignPerformanceTable from "../components/CampaignPerformanceTable";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const ProjectPerformance = () => {
  const { id: projectId, campaignId, adGroupId } = useParams();
  const [reportData, setReportData] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [cpcMin, setCpcMin] = useState(0);
  const [cpcMax, setCpcMax] = useState(0);
  const [totalGoogleAdsClicks, setTotalGoogleAdsClicks] = useState(0);
  const [totalConversions, setTotalConversions] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [totalGasto, setTotalGasto] = useState(0);
  const [totalLucro, setTotalLucro] = useState(0);

  const [roiStatus, setRoiStatus] = useState("");
  const [roiPercentage, setRoiPercentage] = useState(0);

  const [groupName, setGroupName] = useState("");
  const [utm, setUtm] = useState("");

  // Estado para o relatório de CPC
  const [cpcAnalysis, setCpcAnalysis] = useState({
    bestCpcs: [],
    worstCpcs: [],
    suggestions: {},
  });

  const getPeriod = (dateTime) => {
    const hour = dayjs(dateTime).tz("America/Sao_Paulo").hour();
    if (hour >= 0 && hour < 6) return "Madrugada";
    if (hour >= 6 && hour < 12) return "Manhã";
    if (hour >= 12 && hour < 18) return "Tarde";
    return "Noite";
  };

  const analyzeCpcs = (reports) => {
    const cpcData = {};

    // Combina os dados atuais com os históricos
    const allReports = [...historicalData, ...reports];

    allReports.forEach((report) => {
      const period = getPeriod(report.dateTime);
      const cpc = parseFloat(report.currentBid.toFixed(2));
      const roi = report.roiPercentage;

      if (!cpcData[cpc]) {
        cpcData[cpc] = {
          totalOccurrences: 0,
          roiValues: [],
          periods: {},
        };
      }
      cpcData[cpc].totalOccurrences += 1;
      cpcData[cpc].roiValues.push(roi);

      if (!cpcData[cpc].periods[period]) {
        cpcData[cpc].periods[period] = {
          totalOccurrences: 0,
          roiValues: [],
        };
      }
      cpcData[cpc].periods[period].totalOccurrences += 1;
      cpcData[cpc].periods[period].roiValues.push(roi);
    });

    // Calcula estatísticas de ROI para cada CPC
    const cpcList = Object.entries(cpcData).map(([cpc, data]) => {
      const { meanRoi, stdDev } = calculateStatistics(data.roiValues);
      return {
        cpc: parseFloat(cpc),
        totalOccurrences: data.totalOccurrences,
        meanRoi: meanRoi.toFixed(2),
        stdDev: stdDev.toFixed(2),
      };
    });

    // Melhores CPCs ordenados por ROI médio decrescente
    const bestCpcs = cpcList
      .filter((item) => item.totalOccurrences >= 10)
      .sort((a, b) => b.meanRoi - a.meanRoi);

    // Piores CPCs ordenados por ROI médio crescente
    const worstCpcs = [...bestCpcs].reverse();

    // Calcula sugestões de CPC por período
    const suggestions = {};

    ["Madrugada", "Manhã", "Tarde", "Noite"].forEach((period) => {
      let periodCpcs = [];

      Object.entries(cpcData).forEach(([cpc, data]) => {
        const periodData = data.periods[period];
        if (periodData && periodData.totalOccurrences >= 5) {
          const { meanRoi } = calculateStatistics(periodData.roiValues);
          periodCpcs.push({
            cpc: parseFloat(cpc),
            meanRoi,
            occurrences: periodData.totalOccurrences,
          });
        }
      });

      if (periodCpcs.length >= 1) {
        // Filtrar CPCs com ROI positivo
        const positiveRoiCpcs = periodCpcs.filter((item) => item.meanRoi > 0);

        if (positiveRoiCpcs.length > 0) {
          // Caso normal, com CPCs de ROI positivo
          // Encontrar CPC Máximo: CPC com o maior ROI positivo
          let cpcMaxItem = positiveRoiCpcs.reduce((prev, curr) => {
            if (curr.meanRoi > prev.meanRoi) {
              return curr;
            } else if (curr.meanRoi === prev.meanRoi) {
              return curr.occurrences > prev.occurrences ? curr : prev;
            } else {
              return prev;
            }
          });

          // Encontrar CPC Mínimo: CPC com o menor ROI positivo (mais próximo de zero)
          let cpcMinItem = positiveRoiCpcs.reduce((prev, curr) => {
            if (curr.meanRoi < prev.meanRoi) {
              return curr;
            } else if (curr.meanRoi === prev.meanRoi) {
              return curr.occurrences > prev.occurrences ? curr : prev;
            } else {
              return prev;
            }
          });

          let cpcMinValue = cpcMinItem.cpc;
          let cpcMaxValue = cpcMaxItem.cpc;

          // Garantir que cpcMinValue ≤ cpcMaxValue
          if (cpcMinValue > cpcMaxValue) {
            [cpcMinValue, cpcMaxValue] = [cpcMaxValue, cpcMinValue];
          }

          // Se forem iguais, aumentar cpcMax em 10%
          if (cpcMinValue === cpcMaxValue) {
            cpcMaxValue = parseFloat((cpcMaxValue * 1.1).toFixed(2));
          }

          suggestions[period] = {
            cpcMin: cpcMinValue.toFixed(2),
            cpcMax: cpcMaxValue.toFixed(2),
            meanRoi: cpcMaxItem.meanRoi.toFixed(2),
            note: "",
          };
        } else {
          // Caso em que todos os CPCs têm ROI negativo
          // Ordenar CPCs com ROI negativo do menos negativo para o mais negativo
          const negativeRoiCpcs = periodCpcs
            .filter((item) => item.meanRoi < 0)
            .sort((a, b) => b.meanRoi - a.meanRoi); // Mais próximo de zero primeiro

          if (negativeRoiCpcs.length > 0) {
            // CPC Máximo: CPC com ROI negativo menos negativo (mais próximo de zero)
            let cpcMaxItem = negativeRoiCpcs[0];

            // CPC Mínimo: CPC com ROI negativo mais negativo
            let cpcMinItem = negativeRoiCpcs[negativeRoiCpcs.length - 1];

            let cpcMinValue = cpcMinItem.cpc;
            let cpcMaxValue = cpcMaxItem.cpc;

            // Garantir que cpcMinValue ≤ cpcMaxValue
            if (cpcMinValue > cpcMaxValue) {
              [cpcMinValue, cpcMaxValue] = [cpcMaxValue, cpcMinValue];
            }

            // Se forem iguais, aumentar cpcMax em 10%
            if (cpcMinValue === cpcMaxValue) {
              cpcMaxValue = parseFloat((cpcMaxValue * 1.1).toFixed(2));
            }

            suggestions[period] = {
              cpcMin: cpcMinValue.toFixed(2),
              cpcMax: cpcMaxValue.toFixed(2),
              meanRoi: cpcMaxItem.meanRoi.toFixed(2),
              note: "Esses CPCs têm grandes chances de continuar negativos.",
            };
          } else {
            suggestions[period] = {
              cpcMin: "N/A",
              cpcMax: "N/A",
              meanRoi: "N/A",
              note: "",
            };
          }
        }
      } else {
        suggestions[period] = {
          cpcMin: "N/A",
          cpcMax: "N/A",
          meanRoi: "N/A",
          note: "",
        };
      }
    });

    setCpcAnalysis({ bestCpcs, worstCpcs, suggestions });
  };

  const calculateStatistics = (values) => {
    const n = values.length;
    const meanRoi = values.reduce((sum, val) => sum + val, 0) / n;
    const variance =
      values.reduce((sum, val) => sum + Math.pow(val - meanRoi, 2), 0) / n;
    const stdDev = Math.sqrt(variance);
    return { meanRoi, stdDev };
  };

  const calculateSummary = (reports) => {
    if (reports.length === 0) return;

    // Pega o último relatório
    const latestReport = reports[reports.length - 1];
    const adGroup = latestReport.campaignDetails?.adGroups[0] || {};

    setTotalGoogleAdsClicks(adGroup.metrics?.clicks || 0);
    setTotalConversions(adGroup.metrics?.conversions || 0);

    const calculatedConversionRate = adGroup.metrics?.clicks
      ? ((adGroup.metrics.conversions / adGroup.metrics.clicks) * 100).toFixed(
          2
        )
      : 0;
    setConversionRate(calculatedConversionRate);

    setTotalGasto(((adGroup.metrics?.cost_micros || 0) / 1000000).toFixed(2));
    setTotalLucro((latestReport.roiRevenue || 0).toFixed(2));

    const currentRoi = latestReport.roiPercentage || 0;
    setRoiPercentage(currentRoi);
    calculateRoiStatus(currentRoi);

    setCpcMin(((adGroup.cpc_bid_micros || 0) / 1000000).toFixed(2));
    setCpcMax(((adGroup.cpc_bid_micros || 0) / 1000000).toFixed(2));
  };

  const calculateRoiStatus = (roi) => {
    let status = "";
    if (roi >= 80) {
      status = `Acima da meta (+${(roi - 55).toFixed(2)}% acima da meta)`;
    } else if (roi >= 50 && roi < 80) {
      status = `Dentro da meta (+${(roi - 50).toFixed(2)}% acima da meta)`;
    } else if (roi >= 40 && roi < 50) {
      status = `Perto da meta (-${(50 - roi).toFixed(2)}% abaixo da meta)`;
    } else {
      status = `Muito abaixo da meta (-${(50 - roi).toFixed(
        2
      )}% abaixo da meta)`;
    }
    setRoiStatus(status);
  };

  const fetchReportData = async (date) => {
    if (projectId && campaignId) {
      setIsLoading(true);
      try {
        // Define o intervalo de datas para dados históricos (últimos 30 dias)
        const historicalStartDate = dayjs()
          .subtract(30, "day")
          .format("YYYY-MM-DD");
        const historicalEndDate = dayjs().format("YYYY-MM-DD");

        // Busca dados históricos especificando o intervalo de datas
        const historicalResponse = await getCampaignReportByDate(
          projectId,
          campaignId,
          adGroupId,
          historicalStartDate,
          historicalEndDate
        );

        setHistoricalData(historicalResponse || []);

        // Verifica se a data é válida antes de fazer a chamada
        if (date) {
          const response = await getCampaignReportByDate(
            projectId,
            campaignId,
            adGroupId,
            date,
            date // Busca dados para a data selecionada
          );

          if (response) {
            const reports = response || [];
            setReportData(reports);
            setError("");

            if (reports.length > 0) {
              const adGroupDetails = reports[0].adGroupDetails;
              setGroupName(
                adGroupDetails?.name || "Nome do Grupo Desconhecido"
              );
              setUtm(adGroupDetails?.utm_campaign || "UTM Desconhecida");

              // Calcula as informações solicitadas
              calculateSummary(reports);

              // Analisa os CPCs
              analyzeCpcs(reports);
            }
          } else {
            setReportData([]);
            setError("Erro ao carregar o relatório.");
          }
        } else {
          setError("Data selecionada é inválida.");
          setReportData([]);
        }
      } catch (err) {
        setError("Erro ao carregar o relatório.");
        setReportData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchReportData(selectedDate);
  }, [projectId, campaignId, selectedDate]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    if (newDate) {
      setSelectedDate(newDate);
    } else {
      setError("Data selecionada é inválida.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* Título principal */}
      <h1 className="text-3xl font-bold mb-6 text-center">
        Desempenho do Grupo
      </h1>

      {/* Seção de seleção de data */}
      <div className="flex justify-center mb-6">
        <input
          type="date"
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="border border-gray-300 p-2 rounded"
        />
      </div>

      {/* Exibe indicador de carregamento enquanto busca dados */}
      {isLoading ? (
        <div className="flex justify-center items-center my-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
        </div>
      ) : error ? (
        <p className="text-red-500 text-center">
          {typeof error === "string"
            ? error
            : error.message || "Ocorreu um erro."}
        </p>
      ) : (
        <>
          {/* Informações principais */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="bg-white p-4 rounded shadow">
              <h2 className="text-xl font-semibold mb-2">Nome do Grupo</h2>
              <p className="text-gray-700">{groupName}</p>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h2 className="text-xl font-semibold mb-2">UTM</h2>
              <p className="text-gray-700">{utm}</p>
            </div>
          </div>

          {/* Destaques: Total Gasto, Lucro e ROI */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div className="bg-white p-6 rounded shadow text-center">
              <h3 className="text-lg font-semibold text-gray-600 mb-2">
                Total Gasto
              </h3>
              <p className="text-2xl font-bold text-gray-800">
                R$ {totalGasto}
              </p>
            </div>
            <div className="bg-white p-6 rounded shadow text-center">
              <h3 className="text-lg font-semibold text-gray-600 mb-2">
                Total de Lucro
              </h3>
              <p className="text-2xl font-bold text-gray-800">
                R$ {totalLucro}
              </p>
            </div>
            <div
              className={`bg-white p-6 rounded shadow text-center ${
                roiPercentage >= 80
                  ? "border-t-4 border-green-500"
                  : roiPercentage >= 50
                  ? "border-t-4 border-blue-500"
                  : roiPercentage >= 40
                  ? "border-t-4 border-yellow-500"
                  : "border-t-4 border-red-500"
              }`}
            >
              <h3 className="text-lg font-semibold text-gray-600 mb-2">
                ROI Atual
              </h3>
              <p className="text-2xl font-bold text-gray-800">
                {roiPercentage.toFixed(2)}%
              </p>
              <p
                className={`mt-2 ${
                  roiPercentage >= 50
                    ? "text-green-600"
                    : roiPercentage >= 40
                    ? "text-yellow-600"
                    : "text-red-600"
                }`}
              >
                {roiStatus}
              </p>
            </div>
          </div>

          {/* Informações adicionais */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
            <div className="bg-white p-4 rounded shadow text-center">
              <h4 className="text-md font-semibold text-gray-600 mb-1">
                CPC Mínimo
              </h4>
              <p className="text-lg text-gray-700">R$ {cpcMin}</p>
            </div>
            <div className="bg-white p-4 rounded shadow text-center">
              <h4 className="text-md font-semibold text-gray-600 mb-1">
                CPC Máximo
              </h4>
              <p className="text-lg text-gray-700">R$ {cpcMax}</p>
            </div>
            <div className="bg-white p-4 rounded shadow text-center">
              <h4 className="text-md font-semibold text-gray-600 mb-1">
                Cliques Google Ads
              </h4>
              <p className="text-lg text-gray-700">{totalGoogleAdsClicks}</p>
            </div>
            <div className="bg-white p-4 rounded shadow text-center">
              <h4 className="text-md font-semibold text-gray-600 mb-1">
                Total de Conversões
              </h4>
              <p className="text-lg text-gray-700">{totalConversions}</p>
              <p className="text-sm text-gray-500">
                Taxa de Conversão: {conversionRate}%
              </p>
            </div>
          </div>

          {/* Tabela de desempenho */}
          <CampaignPerformanceTable
            reports={reportData}
            singleCampaign={true}
          />

          {/* Seção do Relatório de CPC */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Análise de CPC por Período
            </h2>

            {/* Melhores CPCs */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Melhores CPCs</h3>
              <p className="text-gray-600 mb-4">
                A tabela abaixo mostra os CPCs com melhor desempenho em termos
                de ROI médio, considerando dados históricos.
              </p>
              {cpcAnalysis.bestCpcs.length > 0 ? (
                <table className="min-w-full bg-white border">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b text-center">CPC</th>
                      <th className="py-2 px-4 border-b text-center">
                        Total de Ocorrências
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        ROI Médio
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Desvio Padrão
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cpcAnalysis.bestCpcs.map((item, index) => (
                      <tr
                        key={index}
                        className={item.meanRoi > 0 ? "bg-green-50" : ""}
                      >
                        <td className="py-2 px-4 border-b text-center">
                          R$ {item.cpc.toFixed(2)}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.totalOccurrences}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.meanRoi}%
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.stdDev}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Nenhum CPC encontrado.</p>
              )}
            </div>

            {/* Piores CPCs */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Piores CPCs</h3>
              <p className="text-gray-600 mb-4">
                A tabela abaixo mostra os CPCs com pior desempenho em termos de
                ROI médio, considerando dados históricos.
              </p>
              {cpcAnalysis.worstCpcs.length > 0 ? (
                <table className="min-w-full bg-white border">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b text-center">CPC</th>
                      <th className="py-2 px-4 border-b text-center">
                        Total de Ocorrências
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        ROI Médio
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Desvio Padrão
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cpcAnalysis.worstCpcs.map((item, index) => (
                      <tr
                        key={index}
                        className={item.meanRoi < 0 ? "bg-red-50" : ""}
                      >
                        <td className="py-2 px-4 border-b text-center">
                          R$ {item.cpc.toFixed(2)}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.totalOccurrences}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.meanRoi}%
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {item.stdDev}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Nenhum CPC encontrado.</p>
              )}
            </div>

            {/* Sugestão da Kaizen Ads */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4 text-center">
                Sugestão da Kaizen Ads
              </h3>
              <p className="text-gray-600 mb-4 text-center">
                Com base na análise dos dados históricos, recomendamos os
                seguintes ajustes de CPC para cada período:
              </p>
              <table className="min-w-full bg-white border">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-center">Período</th>
                    <th className="py-2 px-4 border-b text-center">
                      CPC Mínimo
                    </th>
                    <th className="py-2 px-4 border-b text-center">
                      CPC Máximo
                    </th>
                    <th className="py-2 px-4 border-b text-center">
                      ROI Médio Esperado
                    </th>
                    <th className="py-2 px-4 border-b text-center">
                      Observação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {["Madrugada", "Manhã", "Tarde", "Noite"].map(
                    (period, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4 border-b text-center">
                          {period}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {cpcAnalysis.suggestions[period]?.cpcMin || "N/A"}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {cpcAnalysis.suggestions[period]?.cpcMax || "N/A"}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {cpcAnalysis.suggestions[period]?.meanRoi || "N/A"}%
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {cpcAnalysis.suggestions[period]?.note || ""}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectPerformance;
